<template>
  <div class="col-12">
    <div class="card">
        <div class="card-header">
            <h5>
                طلبات تغيير نقظة الانطلاق
            </h5>
        </div>
        <div class="card-footer">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-bordered">
                    <thead>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الحاج
                        </th>
                        <th>
                            تغيير من
                        </th>
                        <th>
                            تغيير الى
                        </th>
                        <th>
                            ملاحظات
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="p in orders" :key="p._id">
                            <td>
                                {{ p.date }}
                            </td>
                            <td>
                                {{ p.name }}
                            </td>
                            <td>
                                {{ p.from }}
                            </td>
                            <td>
                                {{ p.to }}
                            </td>
                            <td>
                                {{ p.content }}
                            </td>
                            <td>
                                <span v-if="p.status == 0" class="btn btn-sm btn-warning">
                                    في الانتظار
                                </span>
                                <span v-if="p.status == 1" class="btn btn-sm btn-success">
                                    تم بنجاح
                                </span>
                                <span v-if="p.status == 2" class="btn btn-sm btn-danger">
                                    مرفوض
                                </span>
                            </td>
                            <td>
                                <b-dropdown dropleft style="width: 100%;"
                                    id="dropdown-1x"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    text="خيارات"
                                    variant="secondary"
                                    size="sm"
                                >
                                    <b-dropdown-item @click="current = p" v-b-modal.edit>
                                        <i class="fa fa-edit"></i>
                                        تغيير الحالة
                                    </b-dropdown-item>
                                    <b-dropdown-item style="font-size: 12px" @click="deleteUser(p._id)">
                                        <i class="fa fa-trash"></i>
                                        حذف 
                                    </b-dropdown-item>
                                </b-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="edit" title="تعديل" hide-footer>
        <template v-if="current._id">
            <div class="row">
                <div class="col-12 g">
                    <button class="btn btn-warning btn-block" @click="changeStatus(0)">
                        في الانتظار
                    </button>
                </div>
                <div class="col-12 g">
                    <button class="btn btn-success btn-block" @click="changeStatus(1)">
                        تم بنجاح
                    </button>
                </div>
                <div class="col-12 g">
                    <button class="btn btn-danger btn-block" @click="changeStatus(2)">
                        مرفوض
                    </button>
                </div>
            </div>
        </template>
    </b-modal>
  </div>
</template>


<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            orders: [],
            user: JSON.parse(localStorage.getItem("user")),
            create: {}
        }
    },
    created(){
        if(!checkPer('orders')){
            this.$router.push('/pers')
            return;
        }
        this.getcamps()
    },
    methods: {
        getcamps(){
            var g = this;
            $.post(api + '/admin/web/orders/list', {
                jwt: g.user.jwt,
                type: 2
            }).then(function(r){
                g.orders = r.response;
            })
        },
        deleteUser(id){
            if(confirm("متأكد من حذف")){
                var g = this;
                $.post(api + '/admin/web/orders/delete', {
                    jwt: g.user.jwt,
                    id: id
                }).then(function(a){
                    g.getcamps();
                    alert("تم الحذف بنجاح", 100);
                })
            }
        },
        changeStatus(s){
            var g = this;
            $.post(api + '/admin/web/orders/edit', {
                jwt: g.user.jwt,
                id: g.current._id,
                status: s
            }).then(function(a){
                g.getcamps();
                alert("تم بنجاح", 100);
            })
        },
    }
}
</script>
<style>

</style>